import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M18.3127 0.5H17.8127V1V29.2081C17.8127 30.7905 17.5679 32.0977 17.1 33.1471L17.099 33.1494C16.6443 34.1835 15.9903 34.9481 15.1412 35.4675C14.2902 35.988 13.2384 36.2611 11.9622 36.2611C10.804 36.2611 9.79023 36.0423 8.90986 35.6172C8.02739 35.191 7.34259 34.6065 6.84144 33.8648C6.36007 33.1214 6.11168 32.2434 6.11168 31.2119V30.7119H5.61168H1H0.5V31.2119C0.5 33.3094 0.992453 35.1474 1.99741 36.7046C2.9949 38.2503 4.36475 39.4408 6.09372 40.275L6.09658 40.2764C7.82405 41.0964 9.78291 41.5 11.9622 41.5C14.2736 41.5 16.3067 41.0296 18.0442 40.0696C19.786 39.1073 21.1334 37.6966 22.0836 35.8534C23.0359 34.0061 23.5 31.7844 23.5 29.2081V1V0.5H23H18.3127Z"
          fill="currentColor"
          transform="translate(25, 22)"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
